import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isManagedGroup}}\n  <LinkTo\n    @route={{this.link}}\n    @models={{array @model.auth_method_id @model.id}}\n  >\n    {{@model.displayName}}\n  </LinkTo>\n{{else}}\n  <LinkTo @route={{this.link}} @model={{@model.id}}>\n    {{@model.displayName}}\n  </LinkTo>\n{{/if}}", {"contents":"{{#if this.isManagedGroup}}\n  <LinkTo\n    @route={{this.link}}\n    @models={{array @model.auth_method_id @model.id}}\n  >\n    {{@model.displayName}}\n  </LinkTo>\n{{else}}\n  <LinkTo @route={{this.link}} @model={{@model.id}}>\n    {{@model.displayName}}\n  </LinkTo>\n{{/if}}","moduleName":"admin/components/link-to-principal/index.hbs","parseOptions":{"srcName":"admin/components/link-to-principal/index.hbs"}});
import Component from '@glimmer/component';

const principalTypeRoutes = {
  user: 'scopes.scope.users.user',
  group: 'scopes.scope.groups.group',
  'managed-group':
    'scopes.scope.auth-methods.auth-method.managed-groups.managed-group',
};

export default class LinkToPrincipalComponent extends Component {
  /**
   * return true if its a managed group model
   * @type {boolean}
   */
  get isManagedGroup() {
    return this.args.model.constructor.modelName === 'managed-group';
  }

  /**
   * return route based on principal type
   * @type {string}
   */
  get link() {
    return principalTypeRoutes[this.args.model.constructor.modelName];
  }
}
