import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Hds::Badge\n  @icon={{this.icon}}\n  @text={{t\n    (concat 'resources.role.principal.types.' @model.constructor.modelName)\n  }}\n/>", {"contents":"<Hds::Badge\n  @icon={{this.icon}}\n  @text={{t\n    (concat 'resources.role.principal.types.' @model.constructor.modelName)\n  }}\n/>","moduleName":"admin/components/principal-type-badge/index.hbs","parseOptions":{"srcName":"admin/components/principal-type-badge/index.hbs"}});
import Component from '@glimmer/component';

const principalTypes = {
  user: 'user',
  group: 'folder-users',
  'managed-group': 'cloud-lock',
};
export default class PrincipalTypeBadgeComponent extends Component {
  /**
   * Display icons based on the pricipal type
   * @type {string}
   */

  get icon() {
    return principalTypes[this.args.model.constructor.modelName];
  }
}
