import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @model.type}}\n  {{component\n    (concat 'form/credential/' @model.type)\n    model=@model\n    types=this.credentialTypes\n    submit=@submit\n    cancel=@cancel\n    changeType=@changeType\n  }}\n{{/if}}", {"contents":"{{#if @model.type}}\n  {{component\n    (concat 'form/credential/' @model.type)\n    model=@model\n    types=this.credentialTypes\n    submit=@submit\n    cancel=@cancel\n    changeType=@changeType\n  }}\n{{/if}}","moduleName":"admin/components/form/credential/index.hbs","parseOptions":{"srcName":"admin/components/form/credential/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { types } from 'api/models/credential';

export default class FormCredentialComponent extends Component {
  // =services
  @service features;

  // =attributes
  /**
   * Returns an array of available credential types the user can create
   * @type {object}
   */
  get credentialTypes() {
    return this.features.isEnabled('json-credentials')
      ? types
      : types.filter((type) => type !== 'json');
  }
}
